<template>
  <div>
    <div class="overmask" :style="returnaCor('alphaLogin', false, true)"></div>

    <div class="container" id="Login">
      <div v-if="carregando == true" class="carregando">
        <!--img
          width="100"
          src="https://bboneapp.s3.amazonaws.com/evo-white.png"
        /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>
      <b-row class="mt-5 text-center" v-if="erroConexao != ''">
        <b-col>
          <img :src="logo" style="max-width: 90%" />
          <div class="alert alert-warning">{{ erroConexao }}</div>
          <b-button @click="buscaDadosEmpresa()">Tentar Novamente</b-button>
        </b-col>
      </b-row>
      <b-row v-else class="mt-5">
        <b-col class="text-center">
          <div style="padding: 15% 0"></div>
          <h4 class="text-white text-uppercase text-shadow">Termo LGPD</h4>
          <div class="mt-5 alert alert-warning">
            Nosso aplicativo e site coletam informações para melhorar sua
            experiência de navegação e usabilidade.
            <a href="javascript:void(0)" @click="exibeTermo = !exibeTermo"
              >Consulte nossa política de Proteção de Dados.</a
            >
          </div>
          <div v-if="exibeTermo" v-html="termo" class="bg-white p-3"></div>
          <b-form @submit.stop.prevent="onSubmit" class="mt-4 mb-4 text-left">
            <b-button
              pill
              type="submit"
              class="btn-block mt-3"
              variant="success"
              :style="returnaCor('botaoLogin', true, false)"
              >Aceitar Política</b-button
            >
          </b-form>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import locale from './../locale';
import service from '../services';
import jwt from 'jsonwebtoken';

import colors from './../services/colors';
import { decryptData } from './../services/decryptData';

export default {
  name: 'DashBoard',
  props: {
    msg: String,
  },
  data() {
    return {
      form: {
        senha: null,
      },
      score: 0,
      erroConexao: '',
      carregando: false,
      logo: 'https://bboneapp.s3.amazonaws.com/evo-gerencial-color.png',
      termo: '<p></p>',
      exibeTermo: false,
      value: 80,
      max: 100,
      nomeEmpresa: null,
      emailEmpresa: null,
      temaCor: {
        botaoLogin: '#000000',
        alphaLogin: '#000000',
        botaoFormularios: '#000000',
      },
    };
  },
  components: {},
  methods: {
    returnaCor(chave, comSombra, backgroundTransparent) {
      var rgb = {};
      if (this.temaCor[chave]) rgb = colors.hexToRgb(this.temaCor[chave]);

      if (backgroundTransparent)
        return `background-color: rgba(${rgb.r}, ${rgb.g}, ${rgb.b},0.3);`;
      if (comSombra)
        return `
      box-shadow: 0 5px 15px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.9);
      background-color: ${this.temaCor[chave]};
      `;
      else
        return `
      background-color: ${this.temaCor[chave]};
      `;
    },
    showScore(score) {
      this.score = score;
    },
    selecionaTab(valor) {
      this.form.formSelecionado = valor;
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
    popToast() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      var msg = '';
      var arrMsg = this.$validator.errors.all();
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    onSubmit(evt) {
      this.carregando = true;
      service
        .post('Associado', 'aceitarTermoLGPD', {})
        .then((res) => {
          this.carregando = false;
          this.$router.push('/dashboard');
        })
        .catch((e) => {
          this.carregando = false;
          this.$bvToast.toast(e, {
            title: 'Atenção!',
            solid: true,
            variant: 'danger',
            toaster: 'b-toaster-top-full',
            appendToast: false,
            autoHideDelay: 2500,
          });
        });
    },
    buscaTermoLGPD() {
      return new Promise((resolve, reject) => {
        this.carregando = true;
        service
          .post('ModelosContratos', 'BuscaContratoLGPD', {})
          .then((res) => {
            this.termo = res.data[0].modeloContrato;
            this.termo = this.termo
              .replace('#NOME_ASSOCIACAO#', this.nomeEmpresa)
              .replace('#EMAIL_ASSOCIACAO#', this.emailEmpresa);
            console.log(res.data);

            this.carregando = false;
          })
          .catch((e) => {
            this.carregando = false;
            this.erroConexao =
              'Seu aplicativo parece estar offline, verifique sua conexao';
          });
      });
    },
    buscaDadosEmpresa() {
      return new Promise((resolve, reject) => {
        this.carregando = true;
        this.logo = localStorage.getItem('logo');
        const token = localStorage.getItem('user-token');
        if (token) {
          var decoded = jwt.decode(token);
        }
        this.form.idEmpresa = decoded.id_empresa;

        service
          .getAll('g', {}, 'empresas/list/idEmpresa/' + this.form.idEmpresa)
          .then((res) => {
            res.data = decryptData(res.data);

            this.nomeEmpresa = res.data[0].nome;
            this.emailEmpresa = res.data[0].email;
            var temaCor = localStorage.getItem('temaCor');
            var tema = JSON.parse(temaCor);
            this.temaCor = tema;

            this.buscaTermoLGPD();
            this.carregando = false;
            resolve();
          })
          .catch((e) => {
            this.carregando = false;
            this.erroConexao =
              'Seu aplicativo parece estar offline, verifique sua conexao';
          });

        this.carregando = false;
        console.log(this.form.idEmpresa);
      });
    },
  },
  mounted() {
    this.$validator.localize('en', locale);
    this.buscaDadosEmpresa();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.carregando {
  color: #fff;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 99999;
  text-align: center;
  padding-top: 50%;
}

.logoRodape {
  text-align: center;
  margin-top: 15px;
  font-size: 10px;
  display: block;
}
</style>
